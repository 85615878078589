import React, { FC } from 'react'

import {
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import Stack from '@mui/material/Stack'
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid'
import { useBoolean } from 'usehooks-ts'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import Divider from 'src/components/Library/Divider/Divider'
import SimpleForm from 'src/components/Library/SimpleForm/SimpleForm'
import Modal from 'src/components/Modal/Modal'
import { useConfirm } from 'src/lib/hooks/Confirmation/index'
import {
  CREATE_NPS_ASSESSOR,
  DELETE_NPS_ASSESSOR,
  UPDATE_NPS_ASSESSOR,
} from 'src/lib/queries/Settings/NPS/NpsAssessors'
import { useAuth } from 'src/Providers'

import { NPSAssessors, NPSRefetch } from '../NPSAssessorsCell'

interface NPSAssessorsViewProps {
  assessors: NPSAssessors
  refetch: NPSRefetch
}

const NPSAssessorsView: FC<NPSAssessorsViewProps> = ({
  assessors,
  refetch,
}) => {
  const apiRef = useGridApiRef()
  const editAssessor = useBoolean(false)
  const addAssessor = useBoolean(false)
  const [myMap, setMyMap] = React.useState(new Map([['Name', '']]))
  const { hasRole } = useAuth()
  const isSuperAdmin = hasRole(['SUPERADMIN'])
  const confirmDelete = useConfirm()
  const updateMap = (key, value) => {
    setMyMap(myMap.set(key, value))
  }

  const [createNpsAssessor] = useMutation(CREATE_NPS_ASSESSOR, {
    onCompleted: () => {
      toast.success('Assessor Created', {
        duration: 2000,
      })
      refetch()
    },
    refetchQueries: ['NPSAssessorsQuery'],
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
      })
    },
  })

  const [updateNpsAssessor] = useMutation(UPDATE_NPS_ASSESSOR, {
    onCompleted: () => {
      toast.success('Assessor Updated', {
        duration: 2000,
      })
      refetch()
    },
    refetchQueries: ['NPSAssessorsQuery'],
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
      })
    },
  })

  const [deleteNpsAssessor] = useMutation(DELETE_NPS_ASSESSOR, {
    onCompleted: async () => {
      toast.success('Assessor Deleted', {
        duration: 2000,
      })
      refetch()
    },
    refetchQueries: ['NPSAssessorsQuery'],
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
      })
    },
  })

  const handleUpdate = (create: boolean) => {
    if (create) {
      createNpsAssessor({
        variables: {
          input: {
            name: myMap.get('Name'),
            isGlobal: true,
          },
        },
      })
    } else {
      updateNpsAssessor({
        variables: {
          id: myMap.get('Id'),
          input: {
            name: myMap.get('Name'),
            isGlobal: true,
          },
        },
      })
    }
  }

  const CustomFooter = () => (
    <div className={'bg-gray-50'}>
      <Button
        variant="outlined"
        className="bg-none"
        startIcon={<PlusIcon className={'h-5 w-5 stroke-2'} />}
        onClick={() => {
          setMyMap(
            new Map([
              ['Id', ''],
              ['Name', ''],
            ]),
          )
          editAssessor.setTrue()
          addAssessor.setTrue()
        }}
        buttonDataTestId="add-assessor-button"
      >
        Add Assessor
      </Button>
    </div>
  )

  const headerStyle =
    'bg-gray-50 text-gray-500 text-xs font-medium tracking-wider leading-3 uppercase'

  const assessorColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: headerStyle,
      flex: 0.5,
      minWidth: 150,
    },
  ]

  if (isSuperAdmin) {
    assessorColumns.push({
      field: 'actions',
      headerName: 'Actions',
      headerClassName: headerStyle,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => (
        <Stack direction={'row'} spacing={1}>
          <Button
            variant="outlined"
            className="bg-none"
            startIcon={<PencilSquareIcon className={'h-5 w-5 stroke-2'} />}
            onClick={() => {
              setMyMap(
                new Map([
                  ['Id', params.row.id],
                  ['Name', params.row.name],
                ]),
              )
              editAssessor.setTrue()
              addAssessor.setFalse()
            }}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            className="bg-none"
            startIcon={<TrashIcon className={'h-5 w-5 stroke-2'} />}
            onClick={() => {
              confirmDelete({
                title: 'Are you sure you want to delete this assessor?',
              }).then((isConfirmed) => {
                if (!isConfirmed) return
                deleteNpsAssessor({
                  variables: {
                    id: params.row.id,
                  },
                })
              })
            }}
          >
            Delete
          </Button>
        </Stack>
      ),
    })
  }

  return (
    <div className={'flex w-1/2 flex-col'}>
      <h1 className={'text-base leading-7 text-gray-800'}>
        NPS Assessor Types
      </h1>
      <Divider className={'-ml-1 p-0'} />
      <div className={'my-4 h-full w-full rounded bg-gray-50 p-4'}>
        <DataGrid
          apiRef={apiRef}
          columns={assessorColumns}
          rows={assessors || []}
          rowHeight={50}
          columnHeaderHeight={25}
          disableColumnSelector
          disableRowSelectionOnClick
          getRowClassName={(_) => 'bg-white'}
          hideFooterPagination
          hideFooter={!isSuperAdmin}
          slots={{ footer: CustomFooter }}
        />
      </div>
      <Modal
        onClose={() => {
          editAssessor.setFalse()
          addAssessor.setFalse()
        }}
        open={editAssessor.value}
        title={addAssessor.value ? 'Add Assessor' : 'Edit Assessor'}
        dialogClassName={'!p-0 max-w-[720px]'}
      >
        <div className={'p-4'}>
          {editAssessor.value && (
            <SimpleForm records={myMap} handleChange={updateMap} />
          )}
        </div>
        <div className="flex w-full flex-row justify-end border border-t-gray-300 bg-gray-100 p-2">
          <Stack direction={'row'} spacing={2}>
            <Button
              variant={'text'}
              onClick={() => {
                editAssessor.setFalse()
                addAssessor.setFalse()
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleUpdate(addAssessor.value)
                editAssessor.setFalse()
                addAssessor.setFalse()
              }}
            >
              Save
            </Button>
          </Stack>
        </div>
      </Modal>
    </div>
  )
}

export default NPSAssessorsView
